import { Link, useNavigate, useParams } from "react-router-dom";
import NotificationsMenu from "../modals/NotificationsModal";
import UserMenu from "./Sidebar";
import { useEffect, useState } from "react";
import { useUser } from "../../auth/UserContext";
import arrow from "../../icons/arrow.png"
import { usePostPageTrackerV1Mutation } from "../../store/penpalServerAPI";
import SettingsCog from "./SettingsCog";
import HeaderMenu from "./HeaderMenu";

const CommonHeader = () => {
  const user = useUser();
  const [path, setPath] = useState(window.location.pathname);
  const [postPageTrackerV2Mutation, { data, error }] = usePostPageTrackerV1Mutation();

  const pageTrackerAPI = async () => {
    //Regular expresions to match GUIDs, MacAddresses and NaturalID's
    const guidPattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;
    const macPattern = /[0-9a-fA-F]{12}/g;
    const natIdPattern = /[0-9]{2}/g;

    const anonymizePath = (path) => {
      return path.replace(guidPattern, 'GUID').replace(macPattern, 'MAC').replace(natIdPattern, 'NID');
    }

    // Checking to see if in the development environment, if so, do not call API to avoid duplicate entries in DB. 
    // This is because we are using React.StrictMode, thus it double renders everything in attempt to check for errors and other issues.
    if (process.env.NODE_ENV !== 'development') {
      const pageData = {
        userId: user.UserID,
        prettyURL: user.SelectedRole.concat(anonymizePath(path)),
        rawURL: user.SelectedRole.concat(path)
      };

      try {
        let res = await postPageTrackerV2Mutation(pageData);
      }
      catch {
        console.error("PageTracker API Error: ", error)
      }
    }
  }

  useEffect(() => {
    // Change the color above the safe area to sunburst for mobile devices.
    document.querySelector("meta[name='theme-color']").setAttribute("content", '#f8a832');

    setPath(window.location.pathname);
    pageTrackerAPI();
  }, []);

  return (
    <div className="sticky z-[250] flex flex-col w-full bg-sunburst items-center">
      <div className="w-full flex justify-between items-center py-2">
        <div className="flex items-center pl-4">
          <UserMenu />
        </div>
        <div className="flex pr-4">
          <div className="flex items-center gap-2">
            {user.Authorized ? user.SelectedRole === 'NONE' ? null : (
              <NotificationsMenu />
            ) : null}
          </div>
          <div className="mr-3 mt-2">
            <SettingsCog />
          </div>
        </div>
      </div>
      <HeaderMenu />
    </div>
  );
};

export default CommonHeader;
