import { createContext, useContext, useState, useEffect } from "react";
import { useLazyGetUserSettingsQuery, usePatchUserSettingsMutation, usePostUserSettingsMutation } from "../../store/penpalServerAPI";
import { useUser } from "../../auth/UserContext";

const initialUserSettings = {
  protocolPref: null,
  setProtocolPref: () => { },
  rolePref: 'NONE',
  setRolePref: () => { },
  tempPref: 'C',
  setTempPref: () => { },
  activeCageTab: "Cases",
  setActiveCageTab: () => { },
  activeDevicesTab: "Inactive",
  setActiveDevicesTab: () => { },
  activeVetDashboardTab: "Cases",
  setActiveVetDashboardTab: () => { }
}

export const UserSettingsContext = createContext(initialUserSettings);

export const useUserSettings = () => {
  return useContext(UserSettingsContext);
};

export const UserSettingsProvider = ({ children }) => {
  const user = useUser();
  const [protocolPref, setProtocolPref] = useState('');
  const [rolePref, setRolePref] = useState('NONE');
  const [tempPref, setTempPref] = useState('C');
  const [activeCageTab, setActiveCageTab] = useState('Cases');
  const [activeDevicesTab, setActiveDevicesTab] = useState('Inactive');
  const [activeVetDashboardTab, setActiveVetDashboardTab] = useState('Cases');

  const [getUserSettings, {
    data: userSettingsData,
    isFetching: userSettingsIsFetching,
    isLoading: userSettingsIsLoading,
    isSuccess: userSettingsIsSuccess,
    isError: userSettingsIsError,
    error: userSettingsError
  }] = useLazyGetUserSettingsQuery();

  const [postUserSettings, {
    data: postedUserSettings,
    isLoading: postIsLoading,
    isSuccess: postIsSuccess,
    isError: postIsError,
    error: postError
  }] = usePostUserSettingsMutation();

  const [patchUserSettings, {
    data: patchedUserSettings,
    isLoading: patchIsLoading,
    isSuccess: patchIsSuccess,
    isError: patchIsError,
    error: patchError
  }] = usePatchUserSettingsMutation();

  useEffect(() => {
    if (user.UserID) {
      getUserSettings(user.UserID)
    }
  }, [user])

  useEffect(() => {
    if (userSettingsIsError) {
      console.log('Error: ', userSettingsError)
    }
  }, [userSettingsIsError])

  useEffect(() => {
    if (userSettingsIsSuccess && !userSettingsData && user.UserID) {
      postUserSettings({
        UserID: user.UserID,
        RolePref: rolePref,
        TempPref: tempPref,
        ProtocolPref: protocolPref,
        ActiveCageTab: activeCageTab,
        ActiveDevicesTab: activeDevicesTab,
        ActiveVetDashboardTab: activeVetDashboardTab
      })
    } else if (userSettingsData) {
      setRolePref(userSettingsData.rolePref)
      setTempPref(userSettingsData.tempPref)
      setProtocolPref(userSettingsData.protocolPref)
      setActiveCageTab(userSettingsData.activeCageTab)
      setActiveDevicesTab(userSettingsData.activeDevicesTab)
      setActiveVetDashboardTab(userSettingsData.activeVetDashboardTab)
    }
  }, [userSettingsIsSuccess]);

  useEffect(() => {
    if (userSettingsIsSuccess && !userSettingsIsFetching) {
      if (protocolPref !== userSettingsData.protocolPref || tempPref !== userSettingsData.tempPref || rolePref !== userSettingsData.rolePref || activeCageTab !== userSettingsData.activeCageTab || activeDevicesTab !== userSettingsData.activeDevicesTab || activeVetDashboardTab !== userSettingsData.activeVetDashboardTab) {
        patchUserSettings({
          userId: user.UserID,
          protocolPref,
          rolePref,
          tempPref,
          activeCageTab,
          activeDevicesTab,
          activeVetDashboardTab
        })
      }
    }
  }, [protocolPref, rolePref, tempPref, activeCageTab, activeDevicesTab, activeVetDashboardTab])

  return (
    <UserSettingsContext.Provider
      value={{
        protocolPref,
        setProtocolPref,
        rolePref,
        setRolePref,
        tempPref,
        setTempPref,
        activeCageTab,
        setActiveCageTab,
        activeDevicesTab,
        setActiveDevicesTab,
        activeVetDashboardTab,
        setActiveVetDashboardTab
      }}>
      {children}
    </UserSettingsContext.Provider>
  )
};
