import { useEffect, useState } from "react";
import { useGetAllNotificationsV1Query, useGetNotificationTypesV1Query } from "../store/penpalServerAPI";

const useNotifications = () => {
  const [notifs, setNotifs] = useState([]);
  const {
    data: notifsData,
    isLoading: notifsIsLoading,
    isSuccess: notifsDataIsSuccess,
    isError: notifsDataIsError,
    error: notifsDataError,
    refetch: refetchNotifsInfo
  } = useGetAllNotificationsV1Query();

  const {
    data: notifTypes,
    isLoading: notifTypesIsLoading,
    isSuccess: notifTypesIsSuccess,
    isError: notifTypesIsError,
    error: notifTypesError,
    refetch: refetchNotifTypesInfo
  } = useGetNotificationTypesV1Query();

  useEffect(() => {
    if (notifsIsLoading || notifTypesIsLoading) return;
    if (notifsDataIsError || notifTypesIsError) {
      console.error("Error fetching data:", notifsDataError || notifTypesError);
      return;
    }
    if (notifsDataIsSuccess && notifTypesIsSuccess && notifsData && notifTypes) {
      normalizeNotifs(notifsData, notifTypes);
    }
  }, [notifsDataIsSuccess, notifsData, notifTypes, notifTypesIsSuccess, notifsIsLoading, notifTypesIsLoading, notifsDataIsError, notifTypesIsError]);

  const normalizeNotifs = (notifsData, notifTypes) => {
    if (!notifsData || !notifTypes) return;

    let locData = {};
    let deviceLocation = "";
    let notifications = notifsData.map((obj) => {
      if (obj.sessionLogs && obj.sessionLogs[0]) {
        if (obj.sessionLogs[0].building && obj.sessionLogs[0].building.building) 
          deviceLocation = obj.sessionLogs[0].building.building;
          let building = obj.sessionLogs[0].building.building;
          let buildingId = obj.sessionLogs[0].building.buildingId;
        if (obj.sessionLogs[0].room && obj.sessionLogs[0].room.room) 
          deviceLocation = deviceLocation.concat(` / ${obj.sessionLogs[0].room.room}`);
          let room = obj.sessionLogs[0].room.room;
          let roomId = obj.sessionLogs[0].room.roomId;
        if (obj.sessionLogs[0].sessionLog && obj.sessionLogs[0].sessionLog.rack) 
          deviceLocation = deviceLocation.concat(` / Rack ${obj.sessionLogs[0].sessionLog.rack}`);
          let rack = obj.sessionLogs[0].sessionLog.rack;
        if (obj.sessionLogs[0].sessionLog && obj.sessionLogs[0].sessionLog.rackRow) 
          deviceLocation = deviceLocation.concat(` (${obj.sessionLogs[0].sessionLog.rackRow}, `);
          let row = obj.sessionLogs[0].sessionLog.rackRow;
        if (obj.sessionLogs[0].sessionLog && obj.sessionLogs[0].sessionLog.rackColumn) 
          deviceLocation = deviceLocation.concat(` ${obj.sessionLogs[0].sessionLog.rackColumn})`);
          let col = obj.sessionLogs[0].sessionLog.rackColumn;

          locData = {
            building: building,
            buildingId: buildingId,
            room: room,
            roomId: roomId,
            rack: rack,
            rackColumn: col,
            rackRow: row
          }
      }

      return {
        id: obj.notification?.notificationId,
        active: obj.notification?.active,
        deviceId: obj.device?.[0]?.deviceId,
        macAddress: obj.device?.[0]?.macAddress,
        deviceNaturalId: obj.device?.[0]?.naturalId,
        sessionId: obj.session?.sessionId,
        protocolId: obj.session?.protocolId,
        cageCardId: obj.session?.cageCardId,
        sessionNaturalId: obj.session?.naturalId,
        sessionName: obj.session?.name,
        location: deviceLocation,
        caseId: obj.notification?.caseId,
        caseStatus: obj.clinCase?.length > 0 ? obj.clinCase[0]?.caseStatus : undefined,
        falsePositive: obj.notification?.falsePositive,
        probability: obj.notification?.probability,
        typeId: obj.notification?.notificationTypeId,
        name: notifTypes[obj.notification?.notificationTypeId]?.name,
        level: notifTypes[obj.notification?.notificationTypeId]?.level,
        description: notifTypes[obj.notification?.notificationTypeId]?.description,
        timestamp: obj.notification?.timestamp,
        sessionLogs: obj.sessionLogs[0]?.sessionLog,
        locationData: locData
      };
    });
    setNotifs(notifications);
  }

  return {
    notifs,
    notifsIsLoading,
    notifTypesIsLoading,
    refetchNotifsInfo,
    refetchNotifTypesInfo,
  };
};

export default useNotifications;
