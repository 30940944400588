import { useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const ActiveCage = lazy(() => import("../pages/ActiveCage"));
const InactiveCage = lazy(() => import("../pages/InactiveCage"));
const Staff = lazy(() => import("../pages/Staff"));
const BugForm = lazy(() => import("../pages/BugForm"));
const Settings = lazy(() => import("../pages/Settings"));
const ClinCase = lazy(() => import("../pages/ClinCase"));
const VetHome = lazy(() => import("../pages/Vet/VetHome"));
const Devices = lazy(() => import("../pages/Vet/Devices"));
const Notifications = lazy(() => import("../pages/Notifications"));
const ClinicalCases = lazy(() => import("../pages/ClinicalCases"));
const VetDashboard = lazy(() => import("../pages/Vet/VetDashboard"));
const NewNote = lazy(() => import("../components/forms/NewNoteForm"));
const UpdateSession = lazy(() => import("../pages/PI/UpdateSession"));
const CageNotes = lazy(() => import("../components/sections/CageNotes"));
const PageNotFound = lazy(() => import("../components/global/PageNotFound"));
const NewClinCase = lazy(() => import("../components/forms/NewClinCaseForm"));
const LoadingScreen = lazy(() => import("../components/global/LoadingScreen"));
const NewObservation = lazy(() => import("../components/forms/NewObservationForm"));

const Vet = () => {

  useEffect(() => {
    if (localStorage.getItem('penpal path') === null) return;
    if (localStorage.getItem('penpal path') !== null && window.document.location.pathname === localStorage.getItem('penpal path')) {
      localStorage.removeItem('penpal path');
    }
  }, [])

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route path="/" element={<VetHome />} />
          <Route path="/clinical-cases" element={<ClinicalCases />} />
          <Route path="/clinical-cases/:id" element={<ClinCase />}/>
          <Route path="/clinical-cases/new/:sessionId/:naturalId" element={<NewClinCase />} />
          <Route path="/clinical-cases/:id/new-observation" element={<NewObservation />}/>
          <Route path="/staff" element={<Staff />} />
          <Route path="/penpals" element={<Devices />} />
          <Route path="/penpals/:id" element={<ActiveCage />} />
          <Route path="/penpals/:id/:snid" element={<InactiveCage />} />
          <Route path="/penpals/:id/notes/:sessionId" element={<CageNotes />} />
          <Route path="/session/update/:id/:mac" element={<UpdateSession />} />
          <Route path="/penpals/:id/notes/:sessionId/new" element={<NewNote />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notifications/:id" element={<Notifications />} />
          <Route path="/vet-dashboard/:deviceId/:sessionId" element={ <VetDashboard /> } />
          <Route path="/*" element={<PageNotFound/>} />
          <Route path="/support/reportabug" element={<BugForm />} />
          {/* <Route path="/settings" element={<Settings />} /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Vet;
